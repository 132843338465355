












































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { VCalendar } from 'vuetify/lib';
import Alerts from '@/components/Alerts.vue';
import { getDateString, firstDayOfWeek, isDateOnly } from '@/util';
import { getEvents, Event } from '@/calendar';
import { store as businessUnitStore } from '@/modules/businessunit';
import { store as languageStore, getLanguageItemComparator } from '@/modules/language';
import { store as translatorStore } from '@/modules/translator';
import { store as customerStore } from '@/modules/customer';
import { getFullName } from '@/modules/user';
import { CalendarObjectTypes } from '@/modules/calendar';

const formatOptions: [Intl.DateTimeFormatOptions, Intl.DateTimeFormatOptions, Intl.DateTimeFormatOptions, Intl.DateTimeFormatOptions, Intl.DateTimeFormatOptions] = [
  { timeZone: 'UTC', hour: 'numeric' }, // short interval
  { timeZone: 'UTC', hour: 'numeric', minute: '2-digit' }, // short interval with minute
  { timeZone: 'UTC', hour: '2-digit', minute: '2-digit' }, // long interval
  { timeZone: 'UTC', month: 'short' }, // month short
  { timeZone: 'UTC', month: 'long' }, // month long
];

interface InternalEvent extends Event
{
  startTimestamp: string;
  endTimestamp?: string;
}

@Component<Calendar>({
  components: {
    Alerts,
  },
  methods: {
    getFullName,
    getLanguageItemComparator,
  },
  mounted()
  {
    // initial change event
    (this.$refs.calendar as any).checkChange();
  },
})
export default class Calendar extends Vue
{
  private readonly businessUnitStore = businessUnitStore;
  private readonly languageStore = languageStore;
  private readonly translatorStore = translatorStore;
  private readonly customerStore = customerStore;
  private readonly CalendarObjectTypes = CalendarObjectTypes;

  protected readonly filters = {
    businessUnitId: null as number | null,
    languageId: null as number | null,
    translatorId: null as number | null,
    customerId: null as number | null,
    calendarObjectType: null as CalendarObjectTypes | null,
  };

  private showAppointmentCommentDialog = false;

  private showHourOverviewDialog = false;

  private eventsHourOverview: InternalEvent[] = [];

  /**
   * The current date.
   */
  public today = getDateString(new Date());

  /**
   * The currently selected date.
   */
  public model = this.today;

  /**
   * The order of the week days in the calendar.
   */
  public weekdays = [0, 1, 2, 3, 4, 5, 6].map(v => (v + firstDayOfWeek) % 7);

  /**
   * The type of the calendar.
   */
  public type: 'month' | 'week' | 'day' | '4day' = 'month';

  /**
   * The title of the current view.
   */
  public get title(): string
  {
    const [start, end] = this.currentRange || [];
    if (!start || !end)
    {
      return '';
    }

    const monthOption = this.$vuetify.breakpoint.smAndDown && (this.type === 'week' || this.type === '4day') ? 3 : 4;

    const startMonth = this.formatTimestamp(start, formatOptions[monthOption]);
    const endMonth = this.formatTimestamp(end, formatOptions[monthOption]);
    const suffixMonth = startMonth === endMonth ? '' : endMonth;

    const startYear = start.year;
    const endYear = end.year;
    const suffixYear = startYear === endYear ? '' : endYear;

    const startDay = this.$i18n.nth(start.day);
    const endDay = this.$i18n.nth(end.day);

    return this.$t(`header.${this.type}`, {
      startMonth,
      endMonth,
      suffixMonth,
      startYear,
      endYear,
      suffixYear,
      startDay,
      endDay,
    }).toString();
  }

  /**
   * The events to display in the calendar.
   */
  private events: InternalEvent[] = [];

  /**
   * Refreshes the events.
   */
  public refresh(): void
  {
    if (this.lastLoadedRange)
    {
      this.loadEvents(...this.lastLoadedRange);
    }
  }

  /**
   * Loads the events for the given range.
   */
  private loadEvents(start: VueCalendarTimestamp, end: VueCalendarTimestamp): void
  {
    this.lastLoadedRange = [start, end];

    const startDate = this.getDateByTimestamp(start);
    const endDate = this.getDateByTimestamp(end);

    getEvents(startDate, endDate, this.filters.businessUnitId, this.filters.languageId, this.filters.translatorId, this.filters.customerId, this.filters.calendarObjectType)
    .then(events => this.events = events.map(e => this.convertEvent(e)));
  }

  /**
   * Updates the current displayed range of the calender for title generation.
   */
  private updateRange({ start, end }: { start: VueCalendarTimestamp; end: VueCalendarTimestamp }): void
  {
    this.currentRange = [start, end];

    // Load events if new range is not within the old one.
    if (!this.lastLoadedRange || !this.rangeContains(this.lastLoadedRange, this.currentRange))
    {
      this.loadEvents(start, end);
    }
  }

  private selectedHour: number = -1;

  private intervalsFirst() {
   return this.selectedHour >= 0 ?  Math.max( this.selectedHour * 12 - 1, 0) : 0;
  }

  private intervalsMinutes() {
    return this.selectedHour >= 0 ? 5 : 60;
  }

  private intervalsCount(): number
  {
    return this.selectedHour >= 0 ? 12 + 1 : 24;
  }

  private intervalsHeight() {
   return this.selectedHour >= 0 ? 48 * 2 : 48;
  }

  private showIntervalLabel() {
    return this.selectedHour >= 0 ? true : null;
  }

  /**
   * Custom interval format function for calendar, because the default uses 12hr format.
   */
  private formatInterval(timestamp: VueCalendarTimestamp, short?: boolean): string
  {
    const opts = formatOptions[short ? timestamp.minute === 0 ? 0 : 1 : 2];
    return this.formatTimestamp(timestamp, opts);
  }

  /**
   * Date time formatting of a timestamp using the given formatter options.
   */
  private formatTimestamp(timestamp: VueCalendarTimestamp, options: Intl.DateTimeFormatOptions): string
  {
    const formatter = new Intl.DateTimeFormat(this.$i18n.locale, options);
    return formatter.format(this.getDateByTimestamp(timestamp));
  }

  /**
   * Converts an events for internal use in the calendar.
   */
  private convertEvent(event: Event): InternalEvent
  {
    return {
      startTimestamp: this.getEventTimestampByDate(event.start),
      endTimestamp: event.end ? this.getEventTimestampByDate(event.end) : undefined,
      ...event,
    };
  }

  /**
   * Converts a vuetify timestamp to a date object.
   */
  private getDateByTimestamp(timestamp: VueCalendarTimestamp): Date
  {
    return new Date(`${timestamp.date || this.today}T${(timestamp.time || '00:00')}:00Z`);
  }

  /**
   * Formats the date for the event array.
   */
  private getEventTimestampByDate(date: Date): string
  {
    let res = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;

    if (!isDateOnly(date))
    {
      res += ` ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    }

    return res;
  }

  /**
   * Checks if a timestamp range is fully contained in another.
   */
  private rangeContains(container: [VueCalendarTimestamp, VueCalendarTimestamp], range: [VueCalendarTimestamp, VueCalendarTimestamp]): boolean
  {
    const containerStart = this.getDateByTimestamp(container[0]).getTime();
    const containerEnd = this.getDateByTimestamp(container[1]).getTime();
    const rangeStart = this.getDateByTimestamp(range[0]).getTime();
    const rangeEnd = this.getDateByTimestamp(range[1]).getTime();

    return rangeStart >= containerStart && rangeEnd <= containerEnd;
  }

  /**
   * Handled on a click on an event.
   */
  private onEventClick({ event, nativeEvent }: VueCalendarClickEventArg<InternalEvent>): void
  {
    if (event.component)
    {
      const open = () =>
        {
          this.selectedEvent = event;
          this.selectedEventDetailPosition = [nativeEvent.pageX, nativeEvent.pageY];
          setTimeout(() => this.showEventDetails = true, 10);
        };

      if (this.showEventDetails)
      {
        this.showEventDetails = false;
        setTimeout(open, 10);
      }
      else
      {
        open();
      }

      nativeEvent.stopPropagation();
    }
    else if (event.route)
    {
      this.showEventDetails = false;
      this.$router.push(event.route);
    }
  }
  /**
   * The currently selected event for details.
   */
  private selectedEvent: InternalEvent | null = null;
  /**
   * The position for detail menu activation.
   */
  private selectedEventDetailPosition: [number, number] = [0, 0];
  /**
   * Whether the event details are visible.
   */
  private showEventDetails = false;

  /**
   * Switches to day view if a day is clicked in the calendar.
   */
  private onDateClick({ date }: VueCalendarTimestamp): void
  {
    this.model = date;
    this.type = 'day';
  }

  /**
   * Switches to day view if clicked on more link.
   */
  private onMoreClick({ date }: VueCalendarTimestamp): void
  {
    this.model = date;
    this.type = 'day';
  }

  private onTimeClick(timestamp: VueCalendarTimestamp): void
  {
    if (process.env.VUE_APP_CALENDAR_CREATE_CONTRACT_TYPE === 'ON_SITE')
    {
      this.$router.push('onsitetranslation/new?timestamp=' + JSON.stringify(timestamp));
    }

    if (process.env.VUE_APP_CALENDAR_CREATE_CONTRACT_TYPE === 'VIDEO')
    {
      this.$router.push('videotranslation/new?timestamp=' + JSON.stringify(timestamp));
    }

    if (process.env.VUE_APP_CALENDAR_CREATE_CONTRACT_TYPE === 'DOCUMENT')
    {
      this.$router.push('documenttranslation/new?timestamp=' + JSON.stringify(timestamp));
    }
  }

  private onTimeClickRight(timestamp: VueCalendarTimestamp): void
  {
    this.showHourOverviewDialog = true;
    this.eventsHourOverview = [];
    this.eventsHourOverview = this.events.filter(v => {
      const sameYear = timestamp.year === v.start.getFullYear();
      const sameMonth = timestamp.month === v.start.getMonth() + 1;
      const sameDay = timestamp.day === v.start.getDate();
      const sameHour = timestamp.hour === v.start.getHours();
      return sameYear && sameMonth && sameDay && sameHour;
    });

    this.eventsHourOverview = this.eventsHourOverview.sort((one, two) => (one.title < two.title ? -1 : 1));

  /* old test implentation of calenar zoom
    if (this.selectedHour >= 0)
    {
      this.selectedHour = -1;
    } else {
      this.selectedHour = timestamp.hour;
    }*/
  }

  /**
   * Handler for the selection of a datepicker value.
   */
  private onDatePickerSelect(date: string): void
  {
    const split = date.split('-');
    // if type is month, there isn't a day
    if (split.length < 3)
    {
      split.push('01');
    }

    this.model = split.join('-');

    this.isDatePickerOpen = false;
  }

  /**
   * The current range of the calendar.
   */
  private currentRange: [VueCalendarTimestamp, VueCalendarTimestamp] = [null as any, null as any];
  /**
   * The range that was used to load the events.
   */
  private lastLoadedRange: [VueCalendarTimestamp, VueCalendarTimestamp];

  private isDatePickerOpen = false;
}

import _ from 'lodash';
import { Component } from 'vue';

import { eventHandler as documenttranslation } from '@/modules/contract/documenttranslation';
import { eventHandler as onsitetranslation } from '@/modules/contract/onsitetranslation';
import { eventHandler as videotranslation } from '@/modules/contract/videotranslation';
import { eventHandler as invoice } from '@/modules/financialdocument/invoice';
import { eventHandler as task } from '@/modules/task';
import { CalendarObjectTypes } from './modules/calendar';

/**
 * The global list of event handlers.
 */
const eventHandlers: _.Dictionary<EventHandler> = {
  documenttranslation,
  onsitetranslation,
  videotranslation,
  invoice,
  task,
};

export interface Event
{
  /**
   * The start date/time of the event.
   */
  start: Date;
  /**
   * The end date/time of the event.
   */
  end?: Date;
  /**
   * The title to display in the calendar.
   */
  title: string;
  /**
   * The route to go to when clicking on the event.
   */
  route?: string;
  /**
   * The color for the event in the calendar.
   */
  color?: string;

  /**
   * A component to render for details.
   */
  component?: Component;
  /**
   * Bindings used for the component.
   */
  componentBindings?: object;
}

export interface EventHandler
{
  /**
   * Returns the default color for events in the calendar.
   */
  color?: string;

  /**
   * A component to render for details.
   */
  component?: Component;

  /**
   * Returns the events within the given date range and filters
   */
  getEvents(from: Date, until: Date, businessUnitId: number | null, languageId: number | null, translatorId: number | null,
            customerId: number | null, calendarObjectType: CalendarObjectTypes | null): Promise<Event[]>;
}

/**
 * Returns the events within the given date range from all registered handlers.
 */
export function getEvents(from: Date, until: Date, businessUnitId: number | null, languageId: number | null, translatorId: number | null,
                          customerId: number | null, calendarObjectType: CalendarObjectTypes | null): Promise<Event[]>
{
  // apply default color/component to events
  return Promise.all(_.values(eventHandlers)
    .map(h => h.getEvents(from, until, businessUnitId, languageId, translatorId, customerId, calendarObjectType)
      .then(events => events.map(e => _.assign({ color: h.color, component: h.component }, e)))))
    .then(result => _.flatten(result));
}
